<template>
    <div class="resume-edit container my-2">
        
        <form>
            <div class="d-flex justify-content-center img">
                  <img :src="data.logo">
                  <div  class="file img" id="res">
                    <input ref="file" type="file" name="resumeavatar" id='upimg' accept="image/*" @change="fileHandler"/>  
                  </div>
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">名 称<i class="rqd">*</i></label>
                <input type="text" class="form-control w-100 rounded" name="name" placeholder="请输入企业名称" required v-model.trim.lazy="data.name" />
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >性 质<i class="rqd"></i></span>
                <select class="form-control w-100 rounded form-select" v-model="data.nature">
                    <option v-for="(items,index) in NATURE" :key="index" :value="index"> {{ items }} </option>
                </select>
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >类 型<i class="rqd"></i></span>
                <select class="form-control w-100 rounded form-select" v-model="data.type">
                    <option v-for="(items,index) in COMPANY_TYPE" :key="index" :value="index"> {{ items }} </option>
                </select>
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >规 模</span>
                <input type="number" class="form-control w-100 rounded" name="name" placeholder="请输入企业规模人数" v-model.trim.lazy="data.scale" />
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >福 利<i class="rqd"></i></span>
                <div class="w-100" @click="showWelare">
                    <select class="form-select p-2 w-100 rounded" multiple="multiple" v-model="data.welfare">
                        <option v-for="(name, code) in WELFARE" :key="code" :value="code">{{ name }}</option>
                    </select>
                </div>
            </div>

            <div class="input-group my-1 has-feedback">
                <span class="m-1" >坐 标</span>
                <input type="number" class="form-control w-100 rounded" name="name" placeholder="请输入企业坐标" v-model.trim.lazy="data.map" />
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >地 址</span>
                <input type="text" class="form-control w-100 rounded" name="name" placeholder="请输入企业地址" v-model.trim.lazy="data.address" />
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >联系人</span>
                <input type="text" class="form-control w-100 rounded" placeholder="请输入联系人姓名" v-model.trim.lazy="data.contact" />
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >电 话</span>
                <input type="number" class="form-control w-100 rounded" placeholder="请输联系人电话" v-model.lazy="data.contact_tel">
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="" for="inputGroupSelect01">简 介</label>
                
                <textarea class="form-control w-100" rows="3" v-model.lazy="data.description" ></textarea>
            </div>
            
            <upimgs v-if="showImage" :myimages="files" @changed="saveImage" ></upimgs>

            <div class="d-flex justify-content-evenly">
                <button class="btn btn-secondary btn-lg mt-3 mx-2" @click.prevent="cancel">取 消</button>
                <button class="btn btn-success btn-lg mt-3" @click.prevent="submit">提 交</button>
            </div>
            

            
        </form>
    </div>
    <Hint v-if="isOk" :msg="msg" @btnok="isOk=false"></Hint>
    <Cropping v-if="showEditimg" :picture="image" @close="close" @finish="save"></Cropping>
</template>

<script>


import { watch, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import Axios from 'axios'
import { getImageFileFromUrl, getUrlCode } from '@/lib/unitls'
import { COMPANY_TYPE, NATURE, WELFARE} from '@/lib/data'
import Cropping from '@/components/lib/Cropping.vue'
import upimgs from "@/components/lib/upimgs.vue"


export default {
    name:'editCompany',
    components:{
        Cropping,
        upimgs
    },
    
    setup () {    
        let file = ref()
        const router = useRouter()
        const isOk = ref(false)
        const btnStatus = ref(false)
        const msg = { type: 'success', message: '提交成功'}
        const errormsg = ref('请认真输入完整的信息，否则会无法提现')
        const showEditimg = ref(false)
        const image = ref()
        const showAvatar = ref(false)
        let images = ref()
        const files = ref([])
        const showImage = ref(false)
        const newFiles = ref([])

        let data = ref({
            id: null,
            name: '',
            logo: {id: '101', path: "https://picsum.photos/70/70"},
            images: null,
            map:"",
            scale:0,
            address:"",
            description:"",
            contact:"",
            contact_tel:null,
            nature: 0,
            type: 0,
            welfare:[0,1,4]
        })
//获取数据
        function getInfo(id) {
            Axios.get('/factory.json?id=' + id).then((response) =>{
                data.value = response.data.data
                if (data.value.images && data.value.images.length > 0) {
                    Promise.all(data.value.images.map(url => getImageFileFromUrl(url, url))).then(data => {
                        files.value = data
                        showImage.value = true
                    })
                }
            })
        }
        
        onMounted(() =>{
            getInfo(getUrlCode())

        })
//取消编辑
        function cancel() {
            router.push('/company')
        }
//上传Logo
        function fileHandler () {
            image.value = file.value.files[0]
            showEditimg.value = true
        }
//提交请求
        function submit() {
            if ( 30 < data.value.name.length || data.value.name.length < 2 ) {
                btnStatus.value = false
                errormsg.value = '公司名不能小于2个字或大于30个字'
                return
            }
            Axios.get('/test.json?id=100', data.value).then((response) => {
                    if (response.data.code == 200){
                        msg.type = 'success'
                        msg.message = '提交成功'
                        msg.url = '/factory/'
                        isOk.value = true
                        return
                    } else {
                        msg.type = 'danger'
                        msg.message = response.data.message
                        isOk.value = true
                        return
                    }
                })
        }

        /* 获取多个图片文件 */
        function saveImage (files) {
            images.value = files
            console.log(images.value[0].name)
            
        }

        /**上传 logo图片 */
        function save (result) {
            showEditimg.value = false
            const f = new File([URL.createObjectURL(result)], image.value.name, {type: image.value.type})

            const form = new FormData()
            form.append('file', f)
            Axios.post('/api/v1/upload/image', form).then((response) => {
                if(response.data.code == 0) {
                    // data.value.logo.id = response.data.data.id
                    data.value.logo = response.data.data.path
                    console.log(data.value.logo.path)
                }

                console.log(response)
            })    
        }

        function close() {
            showEditimg.value = false
        }

        watch(() => data.value.name, (newValue) => {
            if (newValue.length < 2 || newValue.length > 30) {
                errormsg.value = '企业不能小于2个字或大于30个字'
                alert(errormsg.value)
                btnStatus.value = false
                return
            }
            btnStatus.value = true
            return
        })


        

        return {
            cancel,
            file,
            close,
            NATURE,
            COMPANY_TYPE,
            showAvatar,
            data,
            btnStatus,
            msg,
            showEditimg,
            errormsg,
            fileHandler,
            submit,
            isOk,
            save,
            files,
            newFiles,
            image,
            images,
            showImage,
            saveImage,
            WELFARE
        }
    }
}
</script>

<style scoped>
.resume-edit .input-group-text {
    border-radius: 0.25rem 0 0 0.25rem;
}

.img {
    text-align: center;
    margin-bottom:5px;
}

.img img {
    width: 60px;
    height: 70px;
}

.file {
    position: absolute;
    top:10px;
    width:60px;
    height: 70px;
    border-radius: 4px;
    padding: 4px 12px;
    overflow: hidden;
    text-decoration: none;
    text-indent: 0;
    line-height: 20px;
}

.file input {
    position: absolute;
    font-size: 100px;
    right: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}

.rqd, .errMsg {
    color:red;
}

form select {
    flex: auto;
    border-radius: 0;
    background: #fff;
    background-color: #ffffff;
    border-color: #ffffff;
}

form textarea {
    width: 100%;
    min-height: 80px;
    background-color: #ffffff;
    border-color: #ffffff;
}

form .input-group-text, form .form-control {
    background-color: #ffffff;
    border-color: #ffffff;
}

</style>
